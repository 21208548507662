<template>
  <div ref="wrapper">
    <div v-if="field.label && !isModal" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-tooltip.top.noninteractive="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div>
          <span class="switch">
            <label>
              <input
                v-model="value"
                type="checkbox"
                :class="validationState"
                :disabled="isDisabled"
                @blur="checkRequiredFields"
              />
              <span></span>
            </label>
          </span>
        </div>
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import { checkTime } from "@/components/Projects/Workflows/Designer/Canvas/Components/editorHelpers";
export default {
  props: {
    field: {
      type: Object
    },
    outputValues: {
      type: Array,
      default: () => []
    },
    debugValues: {
      type: Array,
      default: () => []
    },
    areaInvalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    conditionValue: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edited: false,
      value: this.field.value ?? false
    };
  },
  computed: {
    isDisabled() {
      return (
        this.disabled ||
        (this.field.readonly !== undefined && this.field.readonly)
      );
    },
    validationState: function() {
      if (
        this.conditionValue &&
        !this.isDisabled &&
        !this.value &&
        this.areaInvalid
      ) {
        return "is-invalid";
      }

      if (!this.field.required || (!this.edited && !this.areaInvalid)) {
        return "";
      }
      if (
        !this.value ||
        (this.field.type === "time" && !checkTime(this.value))
      ) {
        return "is-invalid";
      }
      return "is-valid";
    }
  },
  watch: {
    value: function() {
      this.edited = true;
      let newValue = this.value;

      if (typeof newValue !== "boolean" && Number(newValue)) {
        newValue = parseFloat(newValue);
      } else if (newValue === "true" || newValue === "false") {
        newValue = Boolean(newValue);
      }

      this.$set(this.field, "value", newValue);
      this.$emit("change", newValue);
    }
  },
  methods: {
    checkRequiredFields() {
      bus.$emit("checkRequiredFields");
    }
  }
};
</script>
